<template lang="pug">
  div
    CategoryBase(:requestopts="request")
</template>

<script>

import CategoryBase from "@/views/App/base/CategoryBase";

export default {

  name: 'Brand',

  components: {CategoryBase},

  data: function () {
    return {
      request: {
        table: 'brands',
        graphs: 'brand',
        goodsTitle: 'Товары бренда',
        goodsWhere: {
          brandid: this.$route.params.id,
        }
      },
    }
  },
  mounted() {
  },
}
</script>
